import React from 'react';
import {IWalletProvider, WalletContext} from "../contexts/Wallet";

export default function useWalletConnect() {
  const context = React.useContext<IWalletProvider>(WalletContext);
  if (!context) {
    throw new Error('Error with wallet context');
  }

  return context;
}
