import React from 'react';
import { IModalProvider, ModalContext } from '../contexts/Modal';

const useModal = (): IModalProvider => {
  const context = React.useContext<IModalProvider>(ModalContext);

  if (context === undefined) {
    throw new Error('Error with modal context');
  }

  return context;
};

export default useModal;
