import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Wallet from './contexts/Wallet';
import Toast from './contexts/Toast';
import Maintenance from './components/Maintenance/Maintenance';
import { Provider } from 'react-redux';
import { globalStore } from './store/store';
import LoadingProvider from './contexts/Loading';
import { ModalProvider } from './contexts/Modal';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={globalStore}>
      <LoadingProvider>
        <ModalProvider>
          <Maintenance>
            <Toast>
              <Wallet>
                <App />
              </Wallet>
            </Toast>
          </Maintenance>
        </ModalProvider>
      </LoadingProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root') as HTMLElement
);
