/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react';
import Loader from '../components/Modal/Loader';
import Modal from '../components/Modal/Modal';

export interface ILoadingProvider {
  children?: React.ReactNode;
  show: () => void;
  hide: () => void;
}

export const LoadingContext = React.createContext<ILoadingProvider>({
  show: () => {},
  hide: () => {},
});

const LoadingProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [isShow, setIsShow] = useState<boolean>(true);

  const show = useCallback(() => {
    setIsShow(true);
  }, [isShow]);

  const hide = useCallback(() => {
    setIsShow(true);
  }, [isShow]);

  return (
    <LoadingContext.Provider value={{ show, hide }}>
      {props.children}
      {isShow && (
        <Modal isOpen={isShow} isClickOutside={true} onClose={hide}>
          <Loader />
        </Modal>
      )}
    </LoadingContext.Provider>
  );
};

export default LoadingProvider;
