export const generateRecaptcha = (): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
        // @ts-ignore
        const {grecaptcha} = window;

        if (!grecaptcha) {
            alert('Recaptcha does not exist.')
        }

        grecaptcha.ready(function () {
            grecaptcha
                .execute('6LccousfAAAAAPnzvnKa4m6DCKFLnjdilHlapyaQ', {action: 'buy'})
                .then((token: string) => resolve(token))
                // @ts-ignore
                .catch(error => reject(error));
        });
    })
}
