import React from 'react';
import useWalletConnect from './useWalletConnect';
import { PROXY_ADDRESS } from '../utils/configs';
import { PROXY_ABI } from '../utils/abi';
import Ether, { ethers } from 'ethers';
import { useToast } from '../contexts/Toast';
import {
  fetchAccessInfo,
  fetchBuyToken,
  fetchCheckWallet,
  fetchDisableToken,
  fetchEnableToken,
  fetchPhaseInfo,
  fetchTokenRandom,
} from '../api/towers.api';
import { generateRecaptcha } from '../utils/recaptcha';
import { useAppSelector } from './store';
import { selectUser } from '../store/reducer';
import ETTPROXY from '../contracts/TowersProxy.json';
import { providerRpc } from '../contexts/Wallet';
import useLoading from './useLoading';
import useModal from './useModal';
import Info from '../components/Modal/Info/Info';

export default function useProxyContract(): {
  buy: (phase: number) => void;
  getProxyInfo: () => Promise<{
    status: unknown;
    activeProxyStage: unknown;
    mintedInProxy: unknown;
  }>;
} {
  const [contract, setContract] = React.useState<Ether.ethers.Contract>();
  const authUser = useAppSelector(selectUser);
  const showToast = useToast();
  const { show, hide } = useLoading();
  const { web3Provider, getContract } = useWalletConnect();
  const { setModal } = useModal();

  React.useEffect(() => {
    if (contract || !web3Provider || !getContract) {
      return;
    }
    const instance = getContract(PROXY_ADDRESS!, PROXY_ABI);
    setContract(instance);
  }, [contract, getContract, web3Provider]);

  const proxyContract = React.useMemo(() => {
    return new ethers.Contract(PROXY_ADDRESS as string, ETTPROXY.abi, providerRpc);
  }, []);

  const buy = React.useCallback(
    async (phase: number) => {
      show();
      if (!authUser.wallet) {
        hide();
        showToast({
          message: 'Please connect your wallet to continue.',
          type: 'error',
        });
        return;
      }

      const { data, status: statusCheckResponse } = await fetchCheckWallet(authUser.wallet);

      if (data?.message === 'You are banned') {
        hide();
        showToast({ message: 'You are banned', type: 'error' });
        return;
      }

      if (statusCheckResponse !== 200) {
        hide();
        const message = !data?.message?.startsWith(
          'This wallet has already been used for minting tokens on blockchain.'
        )
          ? 'You can only buy 1 apartment per wallet'
          : 'This wallet has already been used to mint an Apartment on the blockchain. The limit is 1 Apartment per wallet. Please contact the Ethereum Towers team for further details. ';
        showToast({
          message,
          type: 'error',
        });
        return;
      }

      const { data: phaseResponse } = await fetchPhaseInfo(phase);

      if (!phaseResponse?.message || phaseResponse?.message?.phase === 0) {
        hide();
        showToast({
          message: 'You cannot buy an apartment as the sale has not started yet.',
          type: 'error',
        });
        return;
      }

      const accessResponse = await fetchAccessInfo(authUser.wallet);

      if (
        phaseResponse.message!.isPrivateRound &&
        (accessResponse.status !== 200 ||
          phaseResponse.message!.phase !== accessResponse.data.message.user.phase)
      ) {
        hide();
        showToast({
          message: 'You cannot buy an apartment because you are not on the access list.',
          type: 'error',
        });
        return;
      }

      const recaptchaToken = await generateRecaptcha();
      const { status: statusTokenRandomResponse, data: tokenRandomResponse } =
        await fetchTokenRandom(recaptchaToken);

      if (statusTokenRandomResponse !== 200) {
        let messageError = '';

        switch (statusTokenRandomResponse) {
          case 409:
            messageError =
              'You were placed on a timed ban for rejecting too many transactions, please try again later.';
            break;
          default:
            messageError = 'All tokens have been sold and there are no tokens available for sale.';
            break;
        }
        hide();
        showToast({
          message: messageError,
          type: 'error',
        });
        return;
      }

      const tokenId = tokenRandomResponse.message.signature.tokenId;

      try {
        await fetchDisableToken(tokenId, authUser.wallet);
        const price = ethers.utils.parseEther(String(phaseResponse.message!.phasePrice));
        const redeemResponse = await contract!.redeem(
          tokenRandomResponse.message.signature,
          // {from: authUser.wallet, value: price}
          { from: authUser.wallet, value: price, gasLimit: 350000 }
        );
        await redeemResponse.wait();
        await fetchBuyToken({
          tokenId,
          phase: phaseResponse?.message?.phase,
          buyer: authUser.wallet,
          thHash: redeemResponse.transactionHash,
        });
        hide();
        setModal &&
          setModal({
            showClose: true,
            dialog: <Info text="You successfully purchased an Apartment NFT" />,
          });
      } catch (error: any) {
        if (error.code === 4001) {
          await fetchEnableToken(tokenId);
          hide();
          showToast({
            message:
              'You rejected the transaction, multiple rejections will result in a timed ban.',
            type: 'error',
          });
          return;
        }

        console.error(error);
        hide();
        showToast({
          message: error.message,
          type: 'error',
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authUser.wallet, contract, showToast]
  );

  const getProxyInfo = React.useCallback(async () => {
    const status = await proxyContract.isActive();
    const activeProxyStage = await proxyContract.currentStage();
    const mintedInProxy = await proxyContract.tokenCount();

    return {
      status,
      activeProxyStage: activeProxyStage.toNumber(),
      mintedInProxy: mintedInProxy.toNumber(),
    };
  }, [proxyContract]);
  return { buy, getProxyInfo };
}
