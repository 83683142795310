import React from 'react';
import './Main.css';
import Card from '../Card/Card';
import phasesByEnvironment from '../../constants/phases';
import { ENV } from '../../utils/configs';

const Main: React.FC = () => {
  return (
    <main>
      <section className="main-container">
        <div className="video-container">
          <video
            className="video"
            src="videos/Ethereum-Towers-Homepage-Video.mp4"
            autoPlay
            loop
            muted
            playsInline
          ></video>
        </div>

        <div className="box">
          <div className="main-info">
            <h1>Ethereum Towers Mint Page</h1>
          </div>
          <div className="horizontal-divide-line title-line" />
          <div className="main-content">
            <div className="card-description">
              <h5>How to Buy an Apartment NFT</h5>
              <div className="horizontal-divide-line" />
              <div className="card-description-points">
                <div className="card-description-point-container">
                  <h2 className="card-description-point">
                    <div className="circle-number">1.</div>
                    <div className="card-line" />
                    <h3>
                      Connect your{' '}
                      <a target="_blank" href="https://ethereum.org/en/wallets/" rel="noreferrer">
                        Ethereum wallet
                      </a>{' '}
                      (MetaMask or WalletConnect)
                    </h3>
                  </h2>
                  <h2 className="card-description-point">
                    <div className="circle-number">2.</div>
                    <div className="card-line" />
                    Press the "Buy" button to submit a transaction
                  </h2>
                </div>
                <div className="card-description-point-container">
                  <h2 className="card-description-point">
                    <div className="circle-number">3.</div>
                    <div className="card-line" />
                    <h3>
                      Wait for the transaction to complete on{' '}
                      <a
                        target="_blank"
                        href="https://etherscan.io/address/0x05c892e95183eb9660d6eea5b4baa54015874d53"
                        rel="noreferrer"
                      >
                        Etherscan
                      </a>
                    </h3>
                  </h2>
                  <h2 className="card-description-point last">
                    <div className="circle-number">4.</div>
                    <div className="card-line" />
                    Receive a random Apartment NFT (Standard or Luxury)
                  </h2>
                </div>
              </div>
              <h1 className="footer-note">
                NOTE: Declining multiplie transactions in your wallet in one session will disable
                your ability to mint.
              </h1>
            </div>

            <Card
              // @ts-ignore
              data={phasesByEnvironment[ENV]}
              key={0}
              sold={0}
            />
          </div>

          <div className="metaverse-section">
            <div className="metaverse-section-border" />
            <div className="metaverse-section-container">
              <img src="img/quest-2-promo.png" alt="Ethereum towers" />
              <div className="metaverse-text-section">
                <h2>
                  Create your profile today (
                  <a target="_blank" href="https://wp.ethereumtowers.com/" rel="noreferrer">
                    https://wp.ethereumtowers.com/
                  </a>
                  ) and get ready to join the Tower Ecosystem at the end of July
                </h2>
              </div>
            </div>
            <div className="metaverse-section-border right" />
          </div>
        </div>
      </section>
    </main>
  );
};

export default Main;
