import { configureStore } from '@reduxjs/toolkit';
import globalReducer from "./reducer";

export const globalStore = configureStore({
    reducer: globalReducer,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof globalStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof globalStore.dispatch;
