import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './Info.css';

interface InfoProps {
  text: string;
}

const Info: React.FC<InfoProps> = ({ text }) => {
  return (
    <div className="flex flex-col justify-center items-center py-14 px-16">
      <FontAwesomeIcon id="icon-c" fill="#00d084" className=" mb-6 fa-8x" icon={faCheckCircle} />
      <p className="text-xl text-white font-bold">{text}</p>
    </div>
  );
};

export default Info;
