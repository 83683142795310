export type NetworkConfig = {
    chainId: string,
    chainName: string,
    nativeCurrency: {
        name: string,
        symbol: string,
        decimals: 18,
    },
    rpcUrls: string[],
    blockExplorerUrls: string[],
}

export const MAINTANANCEMODE = process.env.REACT_APP_MAINTENANCEMODE === 'true';

export const {
    REACT_APP_CHAIN_ID: CHAIN_ID,
    REACT_APP_NETWORK: NETWORK,
    REACT_APP_TOKEN_ADDRESS: TOKEN_ADDRESS,
    REACT_APP_PROXY_ADDRESS: PROXY_ADDRESS,
    REACT_APP_API_URL: API_URL,
    REACT_APP_ENV: ENV,
} = process.env;

export const NETWORKS: Record<number, NetworkConfig> = {
    1: {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://mainnet.infura.io/v3/1d8b13064bc74863a25a6bee7c59a8c7'],
        blockExplorerUrls: ['https://etherscan.io'],
    },
    5: {
        chainId: '0x5',
        chainName: 'Goerli Testnet',
        nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18,
        },
        rpcUrls: ['https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
        blockExplorerUrls: ['https://goerli.etherscan.io'],
    }
}
