import React from 'react';
import Modal from '../components/Modal/Modal';

export interface IModalProvider {
  unSetModal?: () => void;
  setModal?: React.Dispatch<
    React.SetStateAction<
      | { dialog: React.ReactNode; showClose?: boolean; title?: string; isClickOutside?: boolean }
      | null
      | undefined
    >
  >;
}

const ModalContext = React.createContext<IModalProvider>({});

const ModalProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const [modal, setModal] = React.useState<{
    dialog: React.ReactNode;
    title?: string;
    showClose?: boolean;
    isClickOutside?: boolean;
  } | null>();

  const unSetModal = React.useCallback(() => {
    setModal(null);
  }, [setModal]);

  return (
    <ModalContext.Provider value={{ unSetModal, setModal }}>
      {props.children}
      {!!modal && (
        <Modal
          title={modal.title}
          isOpen={!!modal.dialog}
          showClose={modal?.showClose}
          isClickOutside={modal?.isClickOutside}
          onClose={unSetModal}
        >
          {modal.dialog}
        </Modal>
      )}
    </ModalContext.Provider>
  );
};

export { ModalProvider, ModalContext };
