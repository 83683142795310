import React, { useCallback } from 'react';
import './Header.css';
import MobileMenu from '../MobileMenu/MobileMenu';
import useWalletConnect from '../../hooks/useWalletConnect';
import cutWallet from '../../utils/cutWallet';
import { useAppSelector } from '../../hooks/store';
import { selectUser } from '../../store/reducer';

const Header: React.FC = () => {
  const [openMobileMenu, setOpenMobileMenu] = React.useState(true);
  const authUser = useAppSelector(selectUser);
  const { connect } = useWalletConnect();

  const handleConnect = useCallback(async () => {
    connect && (await connect());
  }, [connect]);

  return (
    <React.Fragment>
      <MobileMenu open={openMobileMenu} onClose={() => setOpenMobileMenu(true)} />
      <div className="header">
        <div className="logo">
          <img src="img/GroupTwo.png" alt="Ethereum towers" />
        </div>
        <div className="right-block">
          <ul className="social">
            <li>
              <a target="_blank" href="https://twitter.com/ethereumtowers" rel="noreferrer">
                <img src="img/icons/twitter.svg" alt="twitter" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://discord.gg/ethereumworlds" rel="noreferrer">
                <img src="img/icons/discord.svg" alt="discord" />
              </a>
            </li>
          </ul>
          <div className="menu">
            {authUser.wallet !== '' ? (
              <span>Connected: {cutWallet(authUser.wallet)}</span>
            ) : (
              <button className="connect" onClick={handleConnect}>
                Connect wallet
              </button>
            )}
          </div>
        </div>
        <div className="burger" onClick={() => setOpenMobileMenu(true)}>
          <span className="line w-4"></span>
          <span className="line w-6"></span>
          <span className="line w-4"></span>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
