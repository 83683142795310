import React from 'react';
import { ILoadingProvider, LoadingContext } from '../contexts/Loading';

const useLoading = (): ILoadingProvider => {
  const context = React.useContext<ILoadingProvider>(LoadingContext);
  if (context === undefined) {
    throw new Error('Error with modal context');
  }

  return context;
};

export default useLoading;
