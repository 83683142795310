import React from 'react';
import computeClasses from '../../utils/computeClasses';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import './Modal.css';

interface Props {
  children?: React.ReactNode;
  title?: string;
  content?: string;
  onClose: () => void;
  isOpen: boolean;
  isClickOutside?: boolean;
  showClose?: boolean;
}

const Modal: React.FC<Props> = ({
  children,
  title,
  content,
  isOpen,
  onClose,
  isClickOutside = true,
  showClose,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isClickOutside && ref.current && !ref.current.contains(event.target as Node)) {
        onClose && onClose();
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal">
      <div className="modal-dialog" ref={ref}>
        <div className="modal-content">
          {showClose && (
            <div
              className={computeClasses({
                'modal-header': true,
              })}
            >
              <FontAwesomeIcon className="cursor-pointer fa-2x" onClick={onClose} icon={faXmark} />
            </div>
          )}
          <div className="modal-body">
            {children}
            {content && <p>{content}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
