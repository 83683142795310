import React from 'react';
import './Footer.css';
import { TOKEN_ADDRESS, PROXY_ADDRESS } from '../../utils/configs';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="main-footer">
        <div className="footer-info-section">
          <div className="footer-info">
            <p className="verified">Minting Proxy Contract:</p>
            <p className="token">{PROXY_ADDRESS}</p>
          </div>
          <div className="footer-info">
            <p className="verified">Official NFT Contract:</p>
            <p className="token">{TOKEN_ADDRESS}</p>
          </div>
        </div>
        <div className="help">
          <h1>Need help?</h1>
          <h5>
            Visit the official Ethereum Towers{' '}
            <a
              target="_blank"
              href="https://discord.gg/ethereumworlds"
              rel="noreferrer"
            >
              Discord
            </a>{' '}
            and post your question in the #minting-help channel, or send us a message on{' '}
            <a target="_blank" href="https://twitter.com/ethereumtowers" rel="noreferrer">
              Twitter
            </a>
            .
          </h5>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
