import React from 'react';
import './Toast.css';
import computeClasses from "../../utils/computeClasses";

interface Props {
    show: boolean;
    message: string;
    type: 'success' | 'error';
}

const Toast: React.FC<Props> = ({show, message, type}) => {
    return (
        <div className={computeClasses({
            'toast': true,
            [type]: true,
            'show': !!show,
        })}>
            <span className="message">{message}</span>
        </div>
    )
}

export default Toast;