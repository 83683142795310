
export default class LocalStorage {
    private static prefix = 'ett_';

    static add<T>(key: string, value: T) {
        localStorage.setItem(this.prefix + key, JSON.stringify(value));
    }

    static get<T>(key: string) {
        const valueJSON = localStorage.getItem(this.prefix + key);

        if (!valueJSON) {
            return undefined;
        }

        return JSON.parse(valueJSON) as T;
    }

    static remove(key:string) {
        localStorage.removeItem(this.prefix + key);
    }

    static clear() {
        localStorage.clear();
    }
}