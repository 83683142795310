import { IPhase } from '../types/IPhase';

const phasesDev: IPhase = {
  number: 1,
  types: 'Standard & Luxury',
  price: '0.25',
  quantity: 1050,
  floorFrom: 40,
  floorTo: 89,
  type: 'live',
  luxuryCoint: '1 Per Floor',
};

const phasesProd: IPhase[] = [
  {
    number: 1,
    endDate: 1644870239,
    price: '0.3',
    quantity: 888,
    floorFrom: 60,
    floorTo: 90,
    type: 'live',
  },
  {
    number: 2,
    endDate: 1645043039,
    price: '0.4',
    quantity: 888,
    floorFrom: 60,
    floorTo: 90,
    type: 'coming',
  },
  {
    number: 3,
    endDate: 1645215839,
    price: '0.5',
    quantity: 418,
    floorFrom: 1,
    floorTo: 19,
    type: 'coming',
  },
  {
    number: 4,
    endDate: 2649388639,
    price: '0.4',
    quantity: 888,
    floorFrom: 60,
    floorTo: 90,
    type: 'coming',
  },
  {
    number: 5,
    endDate: 3649388639,
    price: '0.4',
    quantity: 888,
    floorFrom: 60,
    floorTo: 90,
    type: 'soldout',
  },
];

const phasesByEnvironment = {
  local: phasesDev,
  dev: phasesDev,
  qa: phasesDev,
  prod: phasesProd,
};

export default phasesByEnvironment;
