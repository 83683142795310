import axios from "axios";
import { API_URL } from "../utils/configs";
import {
  AUTH_REQUEST,
  BASE_RESPONSE,
  BUY_TOKEN_REQUEST,
  CHECK_WALLET_RESPONSE,
  JWT_DECODED,
  JWT_RESPONSE,
  PHASE_RESPONSE,
  SOLD_INFO,
} from "./towers.types";
import LocalStorage from "../utils/localStorage";
import jwt_decode from "jwt-decode";

const api = axios.create({
  baseURL: API_URL,
});

export async function refreshAccessToken(
  wallet: string
): Promise<JWT_RESPONSE> {
  const jwt = LocalStorage.get<JWT_RESPONSE>("jwt");

  if (!jwt) {
    throw Error("JWT does not exist in local storage.");
  }

  const response = await api.post<BASE_RESPONSE<JWT_RESPONSE>>(
    "/auth/refresh",
    {
      wallet,
      refreshToken: jwt.refreshToken,
    }
  );

  LocalStorage.add<JWT_RESPONSE>("jwt", response.data.message);

  return response.data.message;
}

api.interceptors.request.use(
  async (config) => {
    const jwt = LocalStorage.get<JWT_RESPONSE>("jwt");
    config.headers = {
      ...(jwt ? { Authorization: `Bearer ${jwt.accessToken}` } : {}),
      Accept: "application/json",
    };
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const jwtOld = LocalStorage.get<JWT_RESPONSE>("jwt");

    if (!jwtOld) {
        return Promise.reject(error);
    }

    const decoded = jwt_decode<JWT_DECODED>(jwtOld!.accessToken);

    if (error.response.status === 401 && !originalRequest._retry && jwtOld) {
      originalRequest._retry = true;
      const jwt = await refreshAccessToken(decoded.wallet);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + jwt.accessToken;
      return api(originalRequest);
    }

    if (originalRequest._retry && jwtOld) {
      console.log('api');
        LocalStorage.clear();
        window.location.reload();
    }

    return Promise.reject(error);
  }
);

export const fetchAuth = async (body: AUTH_REQUEST) => {
  return api.post<BASE_RESPONSE<JWT_RESPONSE>>(`/auth/auth`, body);
};

export const fetchNonce = async (wallet: string) => {
  return api.get<BASE_RESPONSE<number>>(`/auth/auth/getNonce/${wallet}`);
};

export const fetchCheckWallet = async (wallet: string) => {
  return api
    .get<CHECK_WALLET_RESPONSE>("/contract/check", { params: { wallet } })
    .catch((err) => Promise.resolve(err.response));
};

export const fetchPhases = async () => {
  return api
    .get<BASE_RESPONSE<BASE_RESPONSE<SOLD_INFO>>>(`/contract/soldbyphases`)
    .catch((err) => Promise.resolve(err.response));
};

export const fetchPhaseInfo = async (phaseId: number) => {
  return api
    .get<PHASE_RESPONSE>(`/contract/${phaseId}`)
    .catch((err) => Promise.resolve(err.response));
};

export const fetchAccessInfo = async (wallet: string) => {
  return api
    .get<BASE_RESPONSE<any>>(`/contract/whitelisteduser/${wallet}`)
    .catch((err) => Promise.resolve(err.response));
};

export const fetchTokenRandom = async (token: string) => {
  return api
    .post<BASE_RESPONSE<any>>(`/contract/tokenrandom`, { token })
    .catch((err) => Promise.resolve(err.response));
};

export const fetchEnableToken = async (tokenId: string) => {
  return api.post<BASE_RESPONSE<any>>(`/contract/enable/${tokenId}`);
};

export const fetchDisableToken = async (tokenId: string, wallet: string) => {
  return api.post<BASE_RESPONSE<any>>(`/contract/disable/${tokenId}`, {
    wallet,
  });
};

export const fetchBuyToken = async (body: BUY_TOKEN_REQUEST) => {
  return api.post<BASE_RESPONSE<any>>(`/contract/buy`, body);
};
