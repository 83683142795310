import React from 'react';
import {MAINTANANCEMODE} from "../../utils/configs";
import './Maintenance.css';
import LocalStorage from "../../utils/localStorage";

interface Props {
    children: React.ReactElement;
}

const Maintenance: React.FC<Props> = ({children}) => {
    const isOpenMaintenance = LocalStorage.get('$924!');

    if (!MAINTANANCEMODE || isOpenMaintenance) {
        return children;
    }

    return (
        <div className="maintenance">
            <img src="img/logo.png" alt="Ethereum towers"/>
            <p className="message">Coming soon</p>
        </div>
    )
};

export default Maintenance;